import { useRef } from 'react';

import { useTrackLinks } from 'hooks/useTrackLinks';
import { getClassName } from 'theme/theme';

import { StyledRichText } from './RichText.styled';
import { useLinksHaveUTMParams } from './useLinksHaveUTMParams';

type Props = {
  analyticsData?: {
    eventName: string;
    eventProperties?: Record<string, unknown>;
  };
  html?: string;
  dangerouslySetInnerHTML?: { __html: string };
  className?: string;
  $simple?: boolean;
  $small?: boolean;
  $normalize?: boolean;
  $noMargin?: boolean;
  $textAlignCenter?: boolean;
  $color?: string;
};

export function RichText({
  html,
  dangerouslySetInnerHTML,
  className,
  analyticsData,
  $simple,
  $small,
  $normalize,
  $noMargin,
  $textAlignCenter,
  $color,
}: Props) {
  const ref = useRef<HTMLDivElement>(null);

  useTrackLinks({
    ref,
    eventName: analyticsData?.eventName,
    eventProperties: analyticsData?.eventProperties,
  });

  useLinksHaveUTMParams({ ref });

  const cleanHtml =
    html?.replace(
      /<(p|h[1-6]|blockquote)>(<br\/?>)?<\/(p|h[1-6]|blockquote)>/g,
      '',
    ) || '';

  return (
    <StyledRichText
      ref={ref}
      dangerouslySetInnerHTML={
        dangerouslySetInnerHTML ||
        (cleanHtml && { __html: cleanHtml }) ||
        undefined
      }
      className={getClassName(className || '', ['rich-text'])}
      $small={Boolean($small)}
      $simple={Boolean($simple)}
      $normalize={Boolean($normalize)}
      $textAlignCenter={Boolean($textAlignCenter)}
      $noMargin={Boolean($noMargin)}
      $color={$color}
    />
  );
}
