import { css, styled } from 'styled-components';

import { colors, textStyles as styles } from 'theme/theme';

// the RTE would generate HTML like <u><a>...</a></u> which would show
// up with a black underline instead of blue. override this here
// (#188984192)

const underlineFix = css`
  u {
    &:has(a) {
      text-decoration-color: ${colors.brandBlue};
    }
    &:has(a):hover {
      text-decoration-color: ${colors.hoverBlue};
    }
  }
`;

export const StyledRichText = styled.div<{
  $simple?: boolean;
  $small?: boolean;
  $normalize?: boolean;
  $textAlignCenter?: boolean;
  $noMargin?: boolean;
  $color?: string;
}>`
  overflow-wrap: break-word;

  &.idlst-rchtxt {
    /* block-no-empty */
    ${underlineFix}

    ${(props) =>
      props.$simple &&
      `
    h1, h2, h3, h4, h5 {
      margin: 1em 0;
      font-size: inherit !important;
    }
  `}
    ${(props) =>
      props.$small &&
      `
    ${styles.bodySmall}

    h1, h2, h3, h4 {
      margin: 1em 0;
      ${styles.h4}
    }
  `}

  ${(props) =>
      props.$normalize &&
      `
    h1, h2, h3 {
      ${styles.h3}
    }

    h4, h5 {
      font-size: inherit !important;
    }

    h1, h2, h3, h4, h5 {
      margin: 1em 0;
    }
  `}
  }

  ${(props) => props.$textAlignCenter && 'text-align: center;'};
  ${(props) => props.$noMargin && 'margin: 0;'};
  ${(props) => props.$color && `color: ${props.$color};`};
`;
